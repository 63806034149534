import { Injectable } from '@angular/core'
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ConfirmPasswordRequestDTO, RefreshToken } from './../DTOs/index';
import { ModalComponent } from '../../../../shared/components/modal/modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { LoginRegisterRequestDTO, LoginResponseDTO } from '../DTOs/index';
import { LoginHttpService } from './login.service';
import { RouterPaths } from '../../models/Routing.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoginAuthService {
    constructor(
        private loginSrvice: LoginHttpService,
        private router: Router,
        public dialogService: DialogService) { }

    public login(password: string, userName: string): void {
        this.loginSrvice.login({
            password: password,
            userName: userName
        })
            .pipe(
                take(1)
            ).subscribe((res: LoginResponseDTO) => {
                sessionStorage.setItem('token', res.accessToken);
                sessionStorage.setItem('refreshToken', res.refreshToken);
                localStorage.setItem('username', res.fullName);
                localStorage.setItem('mail', res.userName);
                localStorage.setItem('phone', res.phone);
                if (res.buttonVisibility == 3) {
                    if (res.firstLogin) {
                        localStorage.setItem('firstLogin', "true");
                    }
                    this.router.navigate(['join-employee/dashboard/employers/employer-wizard/details'])
                }
                else {
                    this.router.navigate(['join-employee/dashboard/employers/request-list'])
                }
            });
    }

    public createUser(req: LoginRegisterRequestDTO): void {
        this.loginSrvice.createNewUser(req)
            .subscribe((res) => {
                this.login(req.password, req.email);
                //this.router.navigate(['login'])
            })
    }

    public sendResetPasswordRequest(mail: string): void {
        this.loginSrvice.sendResetPasswordRequest(mail)
            .subscribe(() => {
                //this.router.navigate(['login'])
                this.dialogService.open(ModalComponent, { header: "נשלח בהצלחה", data: { text: " נשלח אליך מייל לאיפוס סיסמה", approve: false }, styleClass: 'success' })
            })
    }

    public ConfirmPasswordReset(req: ConfirmPasswordRequestDTO): void {
        this.loginSrvice.ConfirmPasswordReset(req)
            .subscribe(() => {
                //this.login(req.password,window.location.search.substring(1).split('$')[0]);
                this.router.navigate(['login'])
            })
    }

    public refreshTokenExpiration(req: RefreshToken) {
        this.loginSrvice.refreshTokenExpiration(req)
            .pipe(
                take(1)
            ).subscribe((res: LoginResponseDTO) => {
                sessionStorage.setItem('token', res.accessToken);
                sessionStorage.setItem('refreshToken', res.refreshToken);
            });
    }


}