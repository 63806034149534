
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginRegisterRequestDTO, LoginRequestDTO, LoginResponseDTO, ConfirmPasswordRequestDTO, RefreshToken } from './../DTOs/index';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { APIEndpoints } from 'src/app/shared/models/ApiEndPoints.model';
@Injectable()
export class LoginHttpService {

    constructor(
        private readonly httpClient: HttpClient) { }

    public login(req: LoginRequestDTO): Observable<LoginResponseDTO> {
        const url = `${environment.baseUrl}/${APIEndpoints.LOGIN}`;
        return this.httpClient.post<LoginResponseDTO>(url, req)
            .pipe(map(response => response))
    }

    public createNewUser(req: LoginRegisterRequestDTO): Observable<any> {
        const url = `${environment.baseUrl}/${APIEndpoints.CREATE_USER}`;
        return this.httpClient.post(url, req)
            .pipe(map(response => response))
    }

    public sendResetPasswordRequest(mail: string):Observable<any> {
        const url = `${environment.baseUrl}/${APIEndpoints.SEND_RESET_PASSWORD_REQUEST}?email=${mail}`;
        return this.httpClient.get<any>(url)
        .pipe(map(response => response)) 
    }

    public ConfirmPasswordReset(req: ConfirmPasswordRequestDTO):Observable<any> {
        const url = `${environment.baseUrl}/${APIEndpoints.CONFIRM_RESET_PASSWORD_REQUEST}?hash=${req.hash}&newPassword=${req.password}`;
        return this.httpClient.get<any>(url)
        .pipe(map(response => response)) 
    }

    public refreshTokenExpiration(req:RefreshToken):Observable<LoginResponseDTO> {
        const url = `${environment.baseUrl}/${APIEndpoints.REFRESH_TOKEN}`;
        return this.httpClient.post<LoginResponseDTO>(url, req)
            .pipe(map(response => response))
    }
    

}
